<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->
        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <!-- <el-tooltip content="布局大小" effect="dark" placement="bottom">
                    <size-select id="size-select" class="right-menu-item hover-effect" />
                </el-tooltip> -->

      </template>
      <i class="el-icon-message-solid" @click="noticeGo" style="font-size: 22px;position: relative;top: -13px;margin: 0 10px;"></i>
      <div
          class="avatar-container right-menu-item hover-effect notice"
          @click="goTodo"
          :class="{ 'noticeBg': todoNum > 99 }">
       <span class="todo-num"> {{ +todoNum > 99 ? '99+'+'条待办' : todoNum+'条待办' }}</span>
      </div>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <!--<img :src="avatar" class="user-avatar"> -->
          <span>{{userInfo.real_name}}</span>
          <i style="position:relative;top:-1px" class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
          <!-- <WujieVue width="100%" height="100%" name="vue2" :url="vue2Url">
                        <el-dropdown-item>个人中心</el-dropdown-item>
                    </WujieVue> -->
          <router-link to="/system/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <!-- <el-dropdown-item @click.native="setting = true">
                        <span>布局设置</span>
                    </el-dropdown-item> -->
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <UserInform ref="userInform" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import TopNav from '@/components/TopNav';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import Search from '@/components/HeaderSearch';
import RuoYiGit from '@/components/RuoYi/Git';
import RuoYiDoc from '@/components/RuoYi/Doc';
import UserInform from './userInform/index';
import { getToken } from '@/utils/auth';
import { getTodoCount } from '@/api/system/notice'

export default {
  data() {
    return {
      todoNum: 0,
      setIntervalTodo: null // 用于存储定时器的引用
    };
  },
  created() {
    this.setTodoNum();
    this.getTodoNum();
  },
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc,
    UserInform
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device']),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
    userInfo: {
      get() {
        return this.$store.state.user.userInfo;
      },
    },
  },
  methods: {

    setTodoNum() {
      getTodoCount().then(res => {
        this.todoNum  = res.data;
      });
    },
    goTodo() {
      // console.log("跳转页面的时间触发");
      this.$router.push('/work/todo'); // 待办页面
    },
    getTodoNum() {
      if (getToken()) {
        this.setIntervalTodo = setInterval(() => {
          this.setTodoNum();
        }, 1800000); // 每30分钟获取一次
      }
    },
    beforeDestroy() {
      clearInterval(this.setIntervalTodo);
    },
    noticeGo() {
      this.$refs.userInform.dialogVisible = true
      this.$refs.userInform.getList()
    },


    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$store.dispatch('LogOut').then(() => {
              location.href = '/index';
            });
          })
          .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.notice {
  cursor: pointer;
  padding: 5px 100px;
  border-radius: 5px;
  background-color: #f0f0f0; // 默认背景颜色
  transition: background 0.3s;

  &.noticeBg {
    background-color: #ffcc00; // 提示颜色
  }
}

.todo-num {
  font-size: 14px; // 设置你希望的字体大小
  line-height: 1;  // 调整行高以确保文本垂直居中
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    padding-right: 20px; // 添加右侧内边距

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 12px; // 增加左右内边距
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          position: relative;
          top: -6px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
